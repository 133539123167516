<template>
  <div class="appeals-posts">
    <EditorJSComponent v-if="$options.filters.editor_has_text(description)" :text="JSON.parse(description)" />
    <h2 class="appeals-posts__title" v-if="files && files.length">Документы</h2>
    <ul class="appeals-posts__files" v-if="files && files.length">
      <li v-for="(file, i) in files" :key="i">
        <DocumentCardComponent :document="file" activity />
        <!--        <ListDocumentItem :data="file" activity link />-->
      </li>
    </ul>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
// import ListDocumentItem from "components/ListDocumentItem.vue";
import DocumentCardComponent from "../../documents/components/DocumentCardComponent.vue";

export default {
  name: "AppealsPosts",
  computed: {
    description() {
      return this.$store.state.appeals_posts_item?.department_appeal?.description;
    },
    files() {
      return this.$store.state.appeals_posts_item?.department_appeal?.files;
    },
  },
  components: {
    DocumentCardComponent,
    EditorJSComponent,
    // ListDocumentItem,
  },
};
</script>

<style lang="stylus">
.appeals-posts {

  &__title {
    color var(--dark)
		margin-bottom: 10px
  }

  &__files {
    display grid
    grid-gap 15px

    li {
      display inline-flex
      width 100%
    }
  }
}
</style>
